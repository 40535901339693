import { useState, useEffect } from 'react'
import { GridFilterModel, GridFilterItem } from '@mui/x-data-grid'
import FiltersSidebar from './components/filtersSidebar'
import ComponentsTable from './components/componentsTable'
import { getAxiosInstance } from '../../axiosInstance'
import _ from 'lodash'
import Loader from '../loader'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import dic from '../../dictionary'
import TopBar from './components/topBar/topBar'
import FutureTimelineV2 from './components/futureTimeline/futureTimelineV2'
import { AWS_TECHNOLOGIES } from '../../consts'

const FILTERS_SIDEBAR_WIDTH = '220px'

export interface InventoryTableData {
  id: number
  technology: string
  name: string
  score: number
  status: string
  isCompliant: boolean
  currentVersion: string
  requiredVersion: string
  recommendedVersion: string
  dueDate: string
  lastUpdate: string
  integrationName: string
  integrationType: string
  tags: string[]
  drafttDetails: {
    id: number | undefined
    desiredVersion: string | undefined
  }
}

export interface InventoryFilters {
  policy: number
  status: string[]
  technologies: string[]
  tags: string[]
  dueDate?: string // TODO: Convert to range query
}

export interface InventoryPolicy {
  id: number
  name: string
}

const DEFAULT_POLICY: InventoryPolicy = { id: 1, name: 'End of Life' }

const Inventory = () => {
  const [filters, setFilters] = useState<InventoryFilters>({
    policy: 1,
    status: [],
    technologies: [],
    tags: [],
  })

  const [dataGridFilterModel, setDataGridFilterModel] = useState<GridFilterModel>({
    items: [] as GridFilterItem[],
  })

  const [tableData, setTableData] = useState<InventoryTableData[]>([])
  const [filteredData, setFilteredData] = useState<InventoryTableData[]>([])
  const [graphData, setGraphData] = useState<InventoryTableData[]>([])
  const [selectedPolicy, setSelectedPolicy] = useState<InventoryPolicy>(DEFAULT_POLICY)
  const [policies, setPolicies] = useState<InventoryPolicy[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [technologies, setTechnologies] = useState<string[]>([])
  const [integrationsTypes, setIntegrationsTypes] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPolicies = async () => {
      setLoading(true)
      const response = await getAxiosInstance().post('/v1/policy/search')

      const parsedPolicies = _.sortBy(
        _.map(response, (policy) => ({
          id: policy.id,
          name: policy.name,
        })),
        'id',
      )

      setPolicies(parsedPolicies)
      setSelectedPolicy(_.find(parsedPolicies, { id: DEFAULT_POLICY.id }) || parsedPolicies[0])
      setLoading(false)
    }

    fetchPolicies()
  }, [])

  useEffect(() => {
    setLoading(true)
    const fetchPolicyComponents = async () => {
      const policyComponentResponse = await getAxiosInstance().get(`/policyComponent?policyId=${selectedPolicy.id}`)
      const drafttResponse = await getAxiosInstance().get(`draftt?status=active`)
      const ecrComponents = await getAxiosInstance().get(`/component?technology=${AWS_TECHNOLOGIES.ECR}`)

      const parsedData = parsePolicyComponent(policyComponentResponse.data, drafttResponse.data, ecrComponents.data)
      setTags(_.uniq(_.flatten(_.map(parsedData, (item) => item.tags))))
      setTechnologies(_.uniq(_.flatten(_.map(parsedData, (item) => item.technology))))
      setIntegrationsTypes(_.uniq(_.flatten(_.map(parsedData, (item) => item.integrationType))))
      setTableData(parsedData)
      setFilteredData(parsedData)
      setGraphData(parsedData)
      setLoading(false)
    }

    fetchPolicyComponents()
  }, [selectedPolicy])

  useEffect(() => {
    const applyFilters = () => {
      const dataGridFilters = dataGridFilterModel.items.reduce<Record<string, string>>((acc, filter) => {
        if (filter.field && filter.value) {
          acc[filter.field] = filter.value.toLowerCase() // Adjust logic for filtering
        }
        return acc
      }, {})

      const filtered = tableData.filter((item) => {
        const matchesDataGridFilters = Object.entries(dataGridFilters).every(([key, value]) =>
          String(item[key as keyof InventoryTableData] || '')
            .toLowerCase()
            .includes(value),
        )

        const matchesExternalFilters =
          (!filters.status.length || filters.status.includes(item.status)) &&
          (!filters.technologies.length || filters.technologies.includes(item.technology)) &&
          (!filters.tags.length || filters.tags.every((tag) => item.tags?.includes(tag))) // Add tag filtering

        const matchedDateFilters = filters?.dueDate ? dayjs(item.dueDate).isSame(dayjs(filters.dueDate)) : true

        return matchesDataGridFilters && matchesExternalFilters && matchedDateFilters
      })

      setFilteredData(filtered)
      setGraphData(filtered)
    }

    applyFilters()
  }, [filters, dataGridFilterModel, tableData])

  const parsePolicyComponent = (policyComponentsData: any, drafttData: any, ecrComponentsData: any) => {
    let result: InventoryTableData[] = []

    result = policyComponentsData.map((policyComponent: any) => {
      return {
        id: policyComponent.id,
        technology: policyComponent.displayName,
        name: parseName(policyComponent.name),
        score: parseInt(policyComponent.urgency),
        isCompliant: policyComponent.isCompliant,
        status: calculateComponentStatus(policyComponent),
        currentVersion: policyComponent.currentVersion,
        requiredVersion: policyComponent.desiredVersion,
        recommendedVersion: policyComponent.recommendedVersion,
        dueDate: policyComponent.dueDate ? dayjs(policyComponent.dueDate).format('MMM D, YYYY') : dic.policy_tabs.suggested_table.no_due_date,
        lastUpdate: policyComponent.updated_at,
        tags: _.map(policyComponent.tags, ({ key, value }) => `${key}:${value}`),
        integrationName: policyComponent.integrationName,
        integrationType: policyComponent.integrationType,
        extendedSupportCost: _.get(policyComponent.details, 'cost.extendedSupport.oneYearCost'),
        drafttDetails: {
          id: _.find(drafttData, (draftt) => draftt.componentId === policyComponent.componentId)?.id,
          desiredVersion: _.find(drafttData, (draftt) => draftt.componentId === policyComponent.componentId)?.desiredVersion,
        },
        ecrDetails: _.find(ecrComponentsData, (ecrComponent) => ecrComponent.id === policyComponent.componentId),
        details: {
          fullName: policyComponent.name,
        },
      } as InventoryTableData
    })

    return result
  }

  const calculateComponentStatus = (policyComponent: any) => {
    let result

    if (
      policyComponent.isCompliant === true &&
      policyComponent.desiredVersion != null &&
      policyComponent.recommendedVersion != null &&
      policyComponent.urgency >= 30
    ) {
      result = 'Impending'
    } else if (!policyComponent.isCompliant) {
      result = 'Outdated'
    } else {
      result = 'Supported'
    }

    return result
  }

  const parseName = (name: any) => {
    let result = name

    if (_.startsWith(name, 'projects/') || _.includes(name, 'googleapis.com/')) {
      result = _.last(name.split('/'))
    }

    return result
  }

  const handleTagClick = (tag: string) => {
    setFilters((prev) => ({
      ...prev,
      tags: prev.tags.includes(tag) ? prev.tags.filter((t) => t !== tag) : [...prev.tags, tag],
    }))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Grid container flexDirection="row">
      <Grid item sx={{ width: `${FILTERS_SIDEBAR_WIDTH}` }}>
        <FiltersSidebar
          policies={policies}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          filters={filters}
          setFilters={setFilters}
          tags={tags}
          technologies={technologies}
          integrationTypes={integrationsTypes}
        />
      </Grid>
      <Grid item sx={{ width: `calc(100% - ${FILTERS_SIDEBAR_WIDTH})` }}>
        <TopBar data={filteredData} filtersData={{ setFilters, filters }} />
        <FutureTimelineV2 data={graphData} filtersData={{ setFilters, filters }} />
        <ComponentsTable
          data={filteredData}
          filters={filters}
          onTagClick={handleTagClick}
          filterModel={dataGridFilterModel}
          onFilterModelChange={setDataGridFilterModel}
        />
      </Grid>
    </Grid>
  )
}

export default Inventory
