import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import _ from 'lodash'
import { DrafttClickableChip } from './componentDetails.style'
import dayjs from 'dayjs'
import DrafttAvailableBadge from './drafttAvailableBadge'
import EcrDetails from './ecrDetails'

const ComponentDetails = (props: any) => {
  let description = dayjs(props.componentData.dueDate).isAfter(dayjs())
    ? `${props.componentData.technology} is running on version ${props.componentData.currentVersion}. Update version to ${props.componentData.requiredVersion} by ${props.componentData.dueDate}.`
    : `This component has reached end-of-life on ${props.componentData.dueDate}.`

  let impact

  if (props.componentData.extendedSupportCost != null) {
    impact = `Using this outdated version incurs an annual extended support fee of $${props.componentData.extendedSupportCost}/year.`
  }
  if (props.componentData.technology === 'Google CloudSQL MySQL') {
    impact = `Starting ${props.componentData.dueDate}, this component will be enrolled automatically into extended support, a paid service with hourly costs calculated per core. These fees can add up significantly. To avoid extended support charges and the eventual need for a forced upgrade, it’s essential to act proactively and prepare in advance.`
  } else if (props.componentData.technology === 'Amazon EKS') {
    impact = `Effective ${props.componentData.dueDate}, this component will automatically enter extended support, a paid service with costs calculated hourly per core. AWS charges $0.60 per cluster per hour, amounting to approximately $4,380 per year. These expenses can escalate quickly. To avoid incurring extended support fees and the eventual necessity of a forced upgrade, it’s crucial to take proactive steps and prepare ahead of time.`
  }

  const extractGcpProject = (fullName: string) => {
    let result

    if (_.startsWith(fullName, 'projects/')) {
      result = _.nth(fullName.split('/'), 1)
    } else if (_.includes(props.componentData.details.fullName, 'googleapis.com/')) {
      result = _.nth(fullName.split('/'), 5)
    }

    return result
  }

  return (
    <Grid>
      {props.componentData.dueDate !== 'No Due Date' && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Description
          </Typography>

          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
        </Grid>
      )}

      {impact != null && props.componentData.dueDate !== 'No Due Date' && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Impact
          </Typography>

          <Typography variant="body2" gutterBottom>
            {impact}
          </Typography>
        </Grid>
      )}

      <Grid sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Details
        </Typography>

        <Grid container flexDirection="row">
          <Grid container item xs={6}>
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                Integration:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="body2" gutterBottom>
                {props.componentData.integrationName}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                Last Updated:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="body2" gutterBottom>
                {dayjs(props.componentData.updated_at).format('MMM D, YYYY, h:mm A')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {(_.startsWith(props.componentData.details.fullName, 'projects/') || _.includes(props.componentData.details.fullName, 'googleapis.com/')) && (
          <Grid container flexDirection="row">
            <Grid container item xs={6}>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  Project:
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  {extractGcpProject(props.componentData.details.fullName)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}></Grid>
          </Grid>
        )}

        {props.componentData.ecrDetails && <EcrDetails componentData={props.componentData} />}
      </Grid>

      {props.componentData.tags.length > 0 && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Tags ({props.componentData.tags.length})
          </Typography>

          <Grid container flexDirection="row" alignItems="center">
            {_.map(props.componentData.tags, (tag: any) => (
              <Box key={tag} mr={1}>
                <DrafttClickableChip
                  size="small"
                  color="secondary"
                  variant={props.filters.tags.includes(tag) ? 'filled' : 'outlined'}
                  label={tag}
                  onClick={() => props.onTagClick(tag)}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      )}

      {props.componentData.drafttDetails.id && <DrafttAvailableBadge componentData={props.componentData} />}
    </Grid>
  )
}

export default ComponentDetails
