import { Grid, Paper } from '@mui/material'
import PolicyStatusGraph from '../policyStatusGraph/policyStatusGraph'
import { InventoryFilters, InventoryTableData } from '../..'
import { Dispatch, SetStateAction } from 'react'

const TopBar = ({
  data,
  filtersData,
}: {
  data: InventoryTableData[]
  filtersData: { filters: InventoryFilters; setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  return (
    <div>
      <Paper elevation={1} sx={{ p: 2, ml: 2, mb: 2 }}>
        <Grid container flexDirection="row" spacing={2} alignItems={'center'} overflow={'hidden'} flexWrap="nowrap">
          <Grid item xs={12}>
            <PolicyStatusGraph data={data} filtersData={filtersData}></PolicyStatusGraph>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default TopBar
