import '@fontsource/roboto/300.css'
import { ReactComponent as Logo } from './static/logo.svg'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { styled as muiStyled } from '@mui/material/styles'
import styled from '@emotion/styled'
import { SIDEBAR_WIDTH } from './consts'
import { Box, Grid, TableCell, TableRow, TextField } from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'

export const AppGrid = muiStyled(Grid)`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const MainContentGrid = muiStyled(Grid)`
  padding: 10px 15px 10px calc(${SIDEBAR_WIDTH} + 15px);
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const CenteredTableCell = muiStyled(TableCell)`
  text-align: center;
`

export const ClickableTableRow = muiStyled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`

export const ChartTableCell = muiStyled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
`

export const ContainerBox = muiStyled(Box)`
  border: 1px solid #dde1e6;
  border-radius: 9px;
  background-color: white;
`

export const PageTitle = styled.h1`
  margin-top: 5px;
`

export const PageSubtitle = styled.h3`
  margin-bottom: 0px;
`

export const WidgetTitle = styled.h3`
  margin: 0;
  font-weight: 500;
`

export const AiIcon = muiStyled(AutoAwesome)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const TableSearchField = muiStyled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const DrafttLogo = muiStyled(Logo)`
  height: 100px;
`