import React from 'react'
import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'

const EcrDetails = ({ componentData }: any) => {
  const architectures = _.get(componentData, 'ecrDetails.details.architectures', [])
  const osArchitectures = _.map(architectures, (arch) => _.compact([arch.os, arch.architecture, arch.variant]).join('/'))

  return (
    <Grid container flexDirection="row">
      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Latest Image:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.ecrDetails.details.tagInfo.latestImage ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Multi Architecture:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.ecrDetails.details.isMultiArchitecture ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Pushed At:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.ecrDetails.details.tagInfo.pushedAt).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Last Pull:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.ecrDetails.details.tagInfo.lastestPulledAt).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={1.5}>
          <Typography variant="body2" gutterBottom>
            Digest:
          </Typography>
        </Grid>

        <Grid item xs={10.5}>
          <Typography variant="body2" gutterBottom>
            {componentData.ecrDetails.details.imageManifest.digest}
          </Typography>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" item xs={12}>
        <Grid>
          <Typography variant="body2" gutterBottom>
            OS/Architecture/Variant:
          </Typography>
        </Grid>

        <Grid>
          <ul style={{ margin: 0 }}>
            {_.map(osArchitectures, (osArchitecture: any) => (
              <li key={osArchitecture}>{osArchitecture}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EcrDetails
