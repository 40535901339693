import { ChevronLeft, ChevronRight, RestartAlt } from '@mui/icons-material'
import { Grid, IconButton, Paper, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { InventoryFilters, InventoryTableData } from '../..'
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react'
import TimelineNodeComponent from './timelineNode'
import { ObjectValues } from '../../../../helpers/helpers'
import dayjs from 'dayjs'
import { TimelineScaleOption } from './futureTimelineV2.style'

function closestNumber(month: number, numberList: number[]) {
  return numberList.reduce((prev, curr) => (Math.abs(curr - month) < Math.abs(prev - month) ? curr : prev))
}
function getPositionInPeriod(period: TimelinePeriod, dueDate: string) {
  const totalDays = dayjs(period.end).diff(dayjs(period.start), 'days')
  const daysFromStart = dayjs(dueDate).diff(dayjs(period.start), 'days')
  return (daysFromStart / totalDays) * 100
}

function addComponentToPeriod(period: TimelinePeriod, component: InventoryTableData, dueDate: string) {
  if (!period.technologiesByDate[dueDate]) {
    period.technologiesByDate[dueDate] = {
      positionPercentage: getPositionInPeriod(period, dueDate),
      totalTechnologyAmount: 0,
      totalComponentAmount: 0,
      dueDate,
      technologies: {},
    }
  }
  if (period.technologiesByDate[dueDate].technologies[component.technology]) {
    period.technologiesByDate[dueDate].technologies[component.technology].componentAmount += 1
    period.technologiesByDate[dueDate].technologies[component.technology].versions.add(component.currentVersion)
  } else {
    const versions = new Set<string>()
    versions.add(component.currentVersion)
    period.technologiesByDate[dueDate].technologies[component.technology] = {
      componentAmount: 1,
      versions,
    }
    period.technologiesByDate[dueDate].totalTechnologyAmount += 1
  }
  period.technologiesByDate[dueDate].totalComponentAmount += 1
}

export interface TimelineNode {
  positionPercentage: number
  totalComponentAmount: number
  totalTechnologyAmount: 0
  dueDate: string
  technologies: {
    [key: string]: {
      versions: Set<string>
      componentAmount: number
    }
  }
}

export interface TimelinePeriod {
  start: Date
  end: Date
  label: string
  technologiesByDate: {
    [key: string]: TimelineNode
  }
}
const timelineScale = {
  YEAR: 'YEAR',
  '6_MONTHS': '6_MONTHS',
  '3_MONTHS': '3_MONTHS',
  MONTH: 'MONTH',
} as const
type TimelineScale = ObjectValues<typeof timelineScale>

const FutureTimelineV2 = ({
  data,
  filtersData,
}: {
  data: InventoryTableData[]
  filtersData: { filters: InventoryFilters; setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  const currentDate = new Date()
  const [startDate, setStartDate] = useState(currentDate)
  const [periodLengthMonths, setPeriodLength] = useState(3)
  const [monthAmount, setMonthAmount] = useState(5)
  const [timelineScale, setTimelineScale] = useState<TimelineScale>('YEAR')
  const earliestDueDate = data.reduce((earliest, current) => {
    return dayjs(current.dueDate).isBefore(dayjs(earliest)) ? current.dueDate : earliest
  }, currentDate.toString())

  const getPeriods = (options: { startDate: Date }) => {
    const periods: TimelinePeriod[] = []
    let previousMonth = dayjs(options.startDate).startOf('month').toDate()
    for (let i = 0; i < monthAmount; i++) {
      const nextMonth = dayjs(previousMonth).add(periodLengthMonths, 'months').toDate()
      const period: TimelinePeriod = {
        start: previousMonth,
        end: nextMonth,
        label: dayjs(previousMonth).format('MMM YYYY').toString(),
        technologiesByDate: {},
      }
      for (const component of data) {
        const dueDate = new Date(component.dueDate)
        if (dueDate < period.end && dueDate >= period.start) {
          addComponentToPeriod(period, component, dueDate.toString())
        }
      }
      periods.push(period)
      previousMonth = nextMonth
    }
    return periods
  }

  const [periods, setPeriods] = useState(getPeriods({ startDate: currentDate }))
  const handleTimelineChange = (e: MouseEvent, newScale: TimelineScale) => {
    setTimelineScale(newScale)
    let newPeriodLength: number
    let newMonthAmount: number
    switch (newScale) {
      case 'YEAR':
        newPeriodLength = 3
        newMonthAmount = 5
        break
      case '6_MONTHS':
        newPeriodLength = 2
        newMonthAmount = 4
        break
      case '3_MONTHS':
        newPeriodLength = 1
        newMonthAmount = 3
        break
      case 'MONTH':
        newPeriodLength = 1
        newMonthAmount = 2
        break
    }
    const monthList = []
    for (let month = 0; month <= 11; month += newPeriodLength) {
      monthList.push(month)
    }
    const newStartDate = dayjs(startDate).set('month', closestNumber(startDate.getMonth(), monthList)).startOf('month').toDate()
    setPeriodLength(newPeriodLength)
    setMonthAmount(newMonthAmount)
    setStartDate(newStartDate)
  }
  useEffect(() => {
    setPeriods(getPeriods({ startDate }))
  }, [startDate, data])

  const canScrollBack = dayjs(earliestDueDate).isBefore(dayjs(startDate))

  const onResetClick = (currentDate: any) => {
    setStartDate(currentDate)
    filtersData.setFilters((previous) => ({
      ...previous,
      dueDate: undefined,
    }))
  }

  return (
    <Grid>
      <Paper sx={{ mb: 2, ml: 2 }}>
        <Grid container direction="column" spacing={1} overflow="hidden">
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{
              paddingBottom: '20px',
              '& > .MuiGrid-item': {
                flexShrink: 0,
              },
            }}
          >
            <Grid item sx={{ flexGrow: 1, pl: 2 }}>
              <Typography variant="subtitle2">Timeline</Typography>
            </Grid>

            {/*<Grid item sx={{ pt: 1, mt: -1, pr: 1 }}>*/}
            {/*  <Typography variant="caption">Scale:</Typography>*/}
            {/*</Grid>*/}

            <Grid item>
              <ToggleButtonGroup value={timelineScale} onChange={handleTimelineChange} exclusive size="small">
                <TimelineScaleOption value="YEAR">12 Months</TimelineScaleOption>
                <TimelineScaleOption value="6_MONTHS">6 Months</TimelineScaleOption>
                <TimelineScaleOption value="3_MONTHS">3 Months</TimelineScaleOption>
                <TimelineScaleOption value="MONTH">1 Month</TimelineScaleOption>
              </ToggleButtonGroup>
            </Grid>

            <Grid item sx={{ pl: 1 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setStartDate((prev) => dayjs(prev).subtract(periodLengthMonths, 'months').toDate())}
                    disabled={!canScrollBack}
                    sx={{
                      padding: '4px',
                      '&:hover': { backgroundColor: 'action.hover' },
                      '&.Mui-disabled': {
                        opacity: 0.3,
                      },
                    }}
                  >
                    <ChevronLeft fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => setStartDate((prev) => dayjs(prev).add(periodLengthMonths, 'months').toDate())}
                    sx={{
                      padding: '4px',
                      '&:hover': { backgroundColor: 'action.hover' },
                    }}
                  >
                    <ChevronRight fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip title="Reset">
                <IconButton onClick={() => onResetClick(currentDate)}>
                  <RestartAlt />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 6,
                marginRight: 2,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '2px',
                  paddingRight: '3%',
                  backgroundColor: '#e0e0e0',
                  width: '100%',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {periods.map((period, periodIndex) => {
                  const nextPeriod = periods[periodIndex + 1]
                  return (
                    <div
                      key={`period-${periodIndex}-${period.label}`}
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        key={`tick-${periodIndex}`}
                        style={{
                          height: '20px',
                          width: '2px',
                          display: 'flex',
                          position: 'relative',
                          backgroundColor: '#e0e0e0',
                          transform: 'translateX(-50%)',
                        }}
                      />
                    </div>
                  )
                })}
                {periods.map((period, periodIndex) => {
                  const divs = Object.entries(period.technologiesByDate).map(([date, node], nodeIndex) => {
                    const startingPercentage = (periodIndex / (monthAmount - 1)) * 100
                    const periodPercentage = 1 / (monthAmount - 1)
                    const totalPercentageMultiplier = 0.97
                    const absoluteLocation = (startingPercentage + node.positionPercentage * periodPercentage) * totalPercentageMultiplier
                    return (
                      <div
                        key={`node-${periodIndex}-${date}-${nodeIndex}`}
                        style={{
                          left: `${absoluteLocation}%`,
                          position: 'absolute',
                          transform: 'translateX(-50%)',
                          cursor: 'pointer',
                          zIndex: '100',
                        }}
                      >
                        <TimelineNodeComponent
                          nodeData={{
                            date,
                            node,
                            passedDueDate: dayjs(date).isBefore(dayjs(currentDate)) ? true : false,
                          }}
                          isSelected={filtersData.filters.dueDate ? dayjs(filtersData.filters.dueDate).isSame(dayjs(date)) : false}
                          setFilters={filtersData.setFilters}
                          key={`node-${periodIndex}-${date}-${nodeIndex}`}
                        />
                      </div>
                    )
                  })
                  if (currentDate < period.end && currentDate >= period.start) {
                    const startingPercentage = (periodIndex / (monthAmount - 1)) * 100
                    const periodPercentage = 1 / (monthAmount - 1)
                    const totalPercentageMultiplier = 0.97
                    const currentDatePositionPercentage = getPositionInPeriod(period, currentDate.toString())
                    const absoluteLocation = (startingPercentage + currentDatePositionPercentage * periodPercentage) * totalPercentageMultiplier
                    divs.push(
                      <div
                        key={`today`}
                        style={{
                          pointerEvents: 'none',
                          position: 'absolute',
                          left: `${absoluteLocation}%`,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'nowrap',
                            top: '-17px',
                            left: '-15px',
                            position: 'absolute',
                            color: 'rgba(0, 0, 0, 0.54)',
                            marginBottom: '4px',
                            fontSize: '10px',
                          }}
                        >
                          Today
                        </Typography>

                        <div
                          style={{
                            width: '1px',
                            transform: 'translateX(-50%)',
                            height: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.54)',
                          }}
                        />
                      </div>,
                    )
                  }
                  return divs
                })}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={3}
            sx={{
              paddingBottom: '40px',
              position: 'relative',
              overflow: 'hidden',
              top: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '0px',
                  paddingRight: '1%',
                  backgroundColor: '#e0e0e0',
                  width: '100%',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {periods.map((period, index) => (
                  <div key={`label-${index}-${period.label}`}>
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: 'nowrap',
                        color: 'black',
                        fontWeight: '500',
                      }}
                    >
                      {period.label}
                    </Typography>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default FutureTimelineV2
