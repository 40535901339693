import React from 'react'
import { Typography, Grid, Chip } from '@mui/material'
import { Assistant } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { DrafttAvailableBadgePaper } from './drafttAvailableBadge.style'

const DrafttAvailableBadge = ({ componentData }: any) => {
  const inventoryUrl = `${window.location.origin}/draftt/${componentData.drafttDetails.id}`

  return (
    <DrafttAvailableBadgePaper elevation={0} sx={{ m: 2, p: 1.5, borderRadius: '10px' }}>
      <Grid container flexDirection="row">
        <Grid item xs={10} alignItems="center" justifyContent="center">
          <Grid container flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
            <Assistant color="primary" sx={{ mr: '3px' }} />
            <Typography variant="subtitle2" color="primary">
              Upgrade Plan Available
            </Typography>
            <Chip label="In Progress" size="small" sx={{ ml: 1, borderRadius: 1 }} />
          </Grid>
          <Grid container flexDirection="row">
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              Upgrade {componentData.technology} to {componentData.drafttDetails.desiredVersion}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={2} container flexDirection="column" alignItems="flex-end" justifyContent="center">
          <Button variant="contained" size="small" component="a" href={inventoryUrl} target="_blank" rel="noopener noreferrer" sx={{ mr: 1 }}>
            View Plan
          </Button>
        </Grid>
      </Grid>
    </DrafttAvailableBadgePaper>
  )
}

export default DrafttAvailableBadge
