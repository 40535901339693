import { styled } from '@mui/material/styles'
import { ToggleButton } from '@mui/material'

export const TimelineScaleOption = styled(ToggleButton)`
  border: none;
  text-transform: none;
  font-weight: 400;
  font-size: 12px;
  &.Mui-selected {
    background-color: inherit;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
