import axios, { AxiosInstance } from 'axios'
import { getSessionToken, useDescope } from '@descope/react-sdk'
import _ from 'lodash'
import config from './config'

let HTTPInstance: AxiosInstance

export const useInitializeAxiosWithAuth = () => {
  const { logout } = useDescope()

  HTTPInstance = axios.create({
    baseURL: config.backendUrl,
  })

  HTTPInstance.interceptors.request.use(
    async (config) => {
      try {
        config.headers.Authorization = `Bearer ${getSessionToken()}`
        return config
      } catch (error) {
        console.error('Error while getting access token:', error)
        return Promise.reject(error)
      }
    },
    (error) => {
      console.error('Request interceptor error:', error)
      return Promise.reject(error)
    },
  )

  HTTPInstance.interceptors.response.use(
    (response) => {
      // Modify the response data here (e.g., parse, transform)
      //TODO: handle different response error
      return _.get(response, 'data.items') || _.get(response, 'data')
    },
    (error) => {
      const errorStatusCode = _.get(error, 'response.status')
      if (errorStatusCode === 401) {
        logout()
      }

      return Promise.reject(error)
    },
  )
}

export const getAxiosInstance = () => {
  return HTTPInstance
}
