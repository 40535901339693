import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { Box, CardContent, Grid, IconButton, Link, Paper, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { CODE_BLOCK_FORMATS, INTEGRATIONS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import { getAxiosInstance } from '../../../../../axiosInstance'
import { ArrowBack } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import { useNotification } from '../../../../commons/notification/notificationProvider'
import { IntegrationQuote } from '../../../integrations.style'
import CodeBlock from '../../../../commons/codeBlock'

const AzureIntegrationInstructions = () => {
  const navigate = useNavigate()
  const { showNotification } = useNotification()
  const [integrationName, setIntegrationName] = useState('')
  const [integrationNameError, setIntegrationNameError] = useState('')
  const [tenantId, setTenantId] = useState('')
  const [tenantIdError, setTenantIdError] = useState<any>('')
  const [clientId, setClientId] = useState('')
  const [clientIdError, setClientIdError] = useState<any>('')
  const [clientSecret, setClientSecret] = useState('')
  const [clientSecretError, setClientSecretError] = useState<any>('')
  const [subscriptionId, setSubscriptionId] = useState('')
  const [subscriptionIdError, setSubscriptionIdError] = useState<any>('')
  const [createLoading, setCreateLoading] = useState(false)

  const handleIntegrationNameChange = (event: any) => {
    setIntegrationName(event.target.value)
    if (event.target.value.length < 3) {
      setIntegrationNameError(dic.integrations.instructions.azure.integration_name.error)
    } else {
      setIntegrationNameError('')
    }
  }

  const handleClientIdChange = (event: any) => {
    setClientId(event.target.value)
    if (event.target.value.length >= 36) {
      setClientIdError('')
    } else {
      setClientIdError(dic.integrations.instructions.azure.step_1.step_keys.client_id.error)
    }
  }

  const handleTenantIdChange = (event: any) => {
    setTenantId(event.target.value)
    if (event.target.value.length >= 36) {
      setTenantIdError('')
    } else {
      setTenantIdError(dic.integrations.instructions.azure.step_1.step_keys.tenant_id.error)
    }
  }

  const handleClientSecretChange = (event: any) => {
    setClientSecret(event.target.value)
    if (event.target.value.length >= 36) {
      setClientSecretError('')
    } else {
      setClientSecretError(dic.integrations.instructions.azure.step_2.client_secret_key.error)
    }
  }

  const handleSubscriptionIdChange = (event: any) => {
    setSubscriptionId(event.target.value)
    if (event.target.value.length >= 36) {
      setSubscriptionIdError('')
    } else {
      setSubscriptionIdError(dic.integrations.instructions.azure.step_3.subscription_id_key.error)
    }
  }

  const onBackIconClick = () => {
    navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.azure.type))
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setCreateLoading(true)
    try {
      const response = await getAxiosInstance().post('/integration', {
        name: integrationName,
        type: INTEGRATIONS.azure.type,
        details: {
          clientId,
          tenantId,
          clientSecret,
          subscriptionId,
        },
      })

      if (response.status === 200) {
        navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.azure.type))
      } else {
        throw new Error('Failed to validate integration')
      }
    } catch (error: any) {
      setCreateLoading(false)
      showNotification(dic.integrations.instructions.azure.failed, NOTIFICATION_TYPES.ERROR)
    } finally {
      setCreateLoading(false)
    }
  }

  const isCreateDisabled = () => {
    return (
      !integrationName ||
      !!integrationNameError ||
      !tenantId ||
      !!tenantIdError ||
      !clientId ||
      !!clientIdError ||
      !clientSecret ||
      !!clientSecretError ||
      !subscriptionId ||
      !!subscriptionIdError
    )
  }

  return (
    <Grid>
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>{dic.integrations.instructions.azure.title}</h1>
      </Box>

      <Grid container flexDirection="column" alignItems="start">
        <span> {dic.integrations.instructions.azure.integration_name.fill} </span>
        <TextField
          label={dic.integrations.instructions.azure.integration_name.title}
          variant="standard"
          value={integrationName}
          onChange={handleIntegrationNameChange}
          error={!!integrationNameError}
          helperText={integrationNameError}
          required
          sx={{ mb: '10px', mt: '10px', minWidth: '300px', minHeight: '72px' }}
        />
      </Grid>

      <Paper variant="outlined">
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid>
              <Grid container flexDirection="column" alignItems="start">
                <Typography variant="subtitle1"> {dic.integrations.instructions.azure.connect} </Typography>
                <h3>{dic.integrations.instructions.azure.step_1.title}</h3>
                <Grid container flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.navigate_to}&nbsp;</Typography>
                  <Link target="blank" href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade">
                    {dic.integrations.instructions.azure.step_1.app_registration}
                  </Link>
                </Grid>
                <Grid container flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.click_on}&nbsp; </Typography>
                  <IntegrationQuote>{dic.integrations.instructions.azure.step_1.new_registration}</IntegrationQuote>
                </Grid>
                {dic.integrations.instructions.azure.step_1.enter_name}&nbsp;
                <CodeBlock code={dic.integrations.instructions.azure.step_1.draftt_name} format={CODE_BLOCK_FORMATS.text} />
                <Grid container flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.change_the}&nbsp; </Typography>
                  <IntegrationQuote>{dic.integrations.instructions.azure.step_1.redirect_uri}&nbsp;</IntegrationQuote>
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.to}&nbsp; </Typography>
                  <IntegrationQuote>{dic.integrations.instructions.azure.step_1.web}&nbsp; </IntegrationQuote>
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.and_enter}&nbsp; </Typography>
                </Grid>
                <Grid>
                  <CodeBlock code={dic.integrations.instructions.azure.step_1.draftt_uri} format={CODE_BLOCK_FORMATS.text} />
                </Grid>
                <Grid container flexDirection="row" alignItems="center">
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.click_on}&nbsp; </Typography>
                  <IntegrationQuote>{dic.integrations.instructions.azure.step_1.register}</IntegrationQuote>&nbsp;
                  <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_1.enter_keys}&nbsp; </Typography>
                </Grid>
              </Grid>

              <Grid container flexDirection="row">
                <TextField
                  label={dic.integrations.instructions.azure.step_1.step_keys.client_id.title}
                  variant="standard"
                  value={clientId}
                  onChange={handleClientIdChange}
                  error={!!clientIdError}
                  helperText={clientIdError}
                  required
                  sx={{ minWidth: '320px', minHeight: '72px', mt: '10px', mr: '20px' }}
                />

                <TextField
                  label={dic.integrations.instructions.azure.step_1.step_keys.tenant_id.title}
                  variant="standard"
                  value={tenantId}
                  onChange={handleTenantIdChange}
                  error={!!tenantIdError}
                  helperText={tenantIdError}
                  required
                  sx={{ minWidth: '320px', minHeight: '72px', mt: '10px' }}
                />
              </Grid>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.azure.step_2.title}</h3>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_2.under_application}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_2.certificates_secrets}</IntegrationQuote>
              </Grid>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_2.click_on}&nbsp; </Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_2.new_client_secret}</IntegrationQuote>
              </Grid>
              <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_2.enter}&nbsp; </Typography>
              <CodeBlock code={dic.integrations.instructions.azure.step_2.draftt_description} format={CODE_BLOCK_FORMATS.text} />
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_2.select}&nbsp; </Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_2.expiration_date}</IntegrationQuote>
              </Grid>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_2.click_on}&nbsp; </Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_2.add}&nbsp;</IntegrationQuote>
                <Typography>{dic.integrations.instructions.azure.step_2.copy}&nbsp;</Typography>
              </Grid>

              <TextField
                label={dic.integrations.instructions.azure.step_2.client_secret_key.title}
                variant="standard"
                value={clientSecret}
                onChange={handleClientSecretChange}
                error={!!clientSecretError}
                helperText={clientSecretError}
                required
                sx={{ minWidth: '320px', minHeight: '72px', mt: '10px' }}
              />
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.azure.step_3.title}</h3>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_3.navigate_to}&nbsp;</Typography>
                <Link target="blank" href="https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2">
                  {dic.integrations.instructions.azure.step_3.subscriptions}
                </Link>
              </Grid>
              {dic.integrations.instructions.azure.step_3.enter_subscription}&nbsp;
              <TextField
                label={dic.integrations.instructions.azure.step_3.subscription_id_key.title}
                variant="standard"
                value={subscriptionId}
                onChange={handleSubscriptionIdChange}
                error={!!subscriptionIdError}
                helperText={subscriptionIdError}
                required
                sx={{ minWidth: '320px', minHeight: '72px', mt: '10px' }}
              />
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.azure.step_4.title}</h3>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.click_on_subscription}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.access_control}</IntegrationQuote>&nbsp;
              </Grid>
              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.click_on}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.role_assignments}</IntegrationQuote>&nbsp;
              </Grid>

              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.on_the}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.job_functions_roles}</IntegrationQuote>&nbsp;
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.select}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.reader}</IntegrationQuote>&nbsp;
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.and_click_on}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.next}</IntegrationQuote>&nbsp;
              </Grid>

              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.on_the}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.members_tab}</IntegrationQuote>&nbsp;
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.select}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.user_group}</IntegrationQuote>&nbsp;
              </Grid>

              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.click_on}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.select_members}</IntegrationQuote>&nbsp;
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.and_select}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.select_button}</IntegrationQuote>&nbsp;
              </Grid>

              <Grid container flexDirection="row" alignItems="center">
                <Typography variant="subtitle1">{dic.integrations.instructions.azure.step_4.click_on}&nbsp;</Typography>
                <IntegrationQuote>{dic.integrations.instructions.azure.step_4.review_and_assign}</IntegrationQuote>&nbsp;
              </Grid>
            </Grid>

            <Grid container flexDirection="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: '20px' }}>
              <Button variant="text" color="primary" sx={{ mr: '20px' }} onClick={onBackIconClick}>
                {dic.integrations.instructions.azure.cancel}
              </Button>
              {!createLoading && (
                <Button type="submit" variant="contained" color="primary" disabled={isCreateDisabled()}>
                  {dic.integrations.instructions.azure.create}
                </Button>
              )}
              {createLoading && <CircularProgress color="primary" />}
            </Grid>
          </form>
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default AzureIntegrationInstructions
