import { styled } from '@mui/material/styles'
import { Chip } from '@mui/material'

export const DrafttClickableChip = styled(Chip)({
  cursor: 'pointer',
  borderRadius: 7,
  borderColor: '#bdbdbd',
  height: 20,
  color: '#393a70',
  marginBottom: 3,
  '&.MuiChip-filled': {
    background: '#393a70',
    color: 'white',
  },
})
