import React from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Paper, Grid } from '@mui/material'
import { AiIcon, WidgetTitle } from '../../../../common.css'
import dayjs from 'dayjs'
import { DEFAULT_POLICIES_IDS, INTEGRATIONS, INTEGRATIONS_STATUSES, NOTIFICATION_TYPES, ROUTES } from '../../../../consts'
import InsightCard from './components/insightCard'

const DEFAULT_NUMBER_OF_INSIGHTS = 3

const Insights = (props: any) => {
  const allIntegrations = props.integrations
  const eolPolicyComponent = _.filter(props.policyComponents, (component) => {
    return component.policyId === DEFAULT_POLICIES_IDS.EOL && component.dueDate !== null
  })
  const sortedPolicyComponents = _.orderBy(eolPolicyComponent, (policyComponent) => parseInt(policyComponent.urgency), 'desc')
  const suggestedComponents = _.take(sortedPolicyComponents, DEFAULT_NUMBER_OF_INSIGHTS)

  const inactiveK8sExplorerIntegrations = _.filter(allIntegrations, (integration) => {
    return integration.type === INTEGRATIONS.k8sExplorer.type && _.get(integration, 'details.status') === INTEGRATIONS_STATUSES.INACTIVE
  })

  const inactiveIntegrationsInsights = _.map(inactiveK8sExplorerIntegrations, (integration) => {
    return {
      type: NOTIFICATION_TYPES.ERROR,
      message: `${dic.dashboard.insights.insight_description.inactive_k8s_integration} ${integration.name}`,
      link: _.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', integration.type),
    }
  })

  const topPriorityItems = _.map(suggestedComponents, (component) => {
    const isDueDatePassed = dayjs().isAfter(dayjs(component.dueDate))
    const dueDateFormatted = dayjs(component.dueDate).format('MMM D, YYYY')

    const message = isDueDatePassed
      ? `${dic.dashboard.insights.insight_description.upgrade_notification.prefix} ${component.name} (${component.displayName}), ${dic.dashboard.insights.insight_description.upgrade_notification.due_date_passed} ${dueDateFormatted}`
      : `${dic.dashboard.insights.insight_description.upgrade_notification.prefix} ${component.name} (${component.displayName}), ${dic.dashboard.insights.insight_description.upgrade_notification.upcoming_due_date} ${dueDateFormatted}`

    return {
      type: NOTIFICATION_TYPES.WARNING,
      message: message,
      link: _.replace(ROUTES.POLICIES.SINGLE, ':policyId', DEFAULT_POLICIES_IDS.EOL),
    }
  })

  const insights = _.take(_.concat(inactiveIntegrationsInsights, topPriorityItems), DEFAULT_NUMBER_OF_INSIGHTS)

  return (
    <Paper variant="outlined" sx={{ p: '16px' }}>
      <Grid container flexDirection="column">
        <Grid item>
        <WidgetTitle>
          <AiIcon fontSize="small" sx={{ mr: '10px', verticalAlign: 'text-top' }} />
          {dic.dashboard.insights.title}
        </WidgetTitle>
        </Grid>
        <Grid container item alignItems={'center'} sx={{ mt: '16px' }}>
          {_.map(insights, (insight, index) => {
            return <Grid item xs={12} key={`card-${index}`}>
                <InsightCard key={index} type={insight.type} message={insight.message} link={insight.link} />
             </Grid>
          })}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Insights
