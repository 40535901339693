import React, { useState } from 'react'
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  Autocomplete,
  TextField,
  Tooltip,
} from '@mui/material'
import { InventoryFilters, InventoryPolicy } from '../..'
import _ from 'lodash'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { FilteredChip } from './filtersSidebar.style'

interface SidebarProps {
  filters: InventoryFilters
  setFilters: React.Dispatch<React.SetStateAction<InventoryFilters>>
  policies: InventoryPolicy[]
  selectedPolicy: InventoryPolicy
  setSelectedPolicy: React.Dispatch<React.SetStateAction<InventoryPolicy>>
  tags: string[]
  technologies: string[]
  integrationTypes: string[]
}

const FiltersSidebar: React.FC<SidebarProps> = ({
  policies,
  selectedPolicy,
  setSelectedPolicy,
  filters,
  setFilters,
  tags,
  technologies,
  integrationTypes,
}) => {
  const [statusExpanded, setStatusExpanded] = useState(true)
  const [tagsExpanded, setTagsExpanded] = useState(false)
  const [technologiesExpanded, setTechnologiesExpanded] = useState(false)

  const handleFilterChange = (filterType: keyof InventoryFilters, value: any) => {
    setFilters((prev) => ({ ...prev, [filterType]: value }))
  }

  const handleSelectedPolicyChange = (policyId: string | number) => {
    const policy = _.find(policies, { id: policyId }) as InventoryPolicy | undefined
    if (policy) {
      setSelectedPolicy(policy)
    }
  }

  const toggleStatusExpand = () => {
    setStatusExpanded(!statusExpanded)
  }

  const toggleTagsExpand = () => {
    setTagsExpanded(!tagsExpanded)
  }

  const toggleTechnologiesExpand = () => {
    setTechnologiesExpanded(!technologiesExpanded)
  }

  const handleTagChange = (event: React.SyntheticEvent | null, value?: string[] | string) => {
    if (_.isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        tags: value,
      }))
    } else if (_.isString(value)) {
      setFilters((prev) => ({
        ...prev,
        tags: _.filter(prev.tags, (tag) => tag !== value),
      }))
    }
  }

  const handleTechnologiesChange = (event: React.SyntheticEvent | null, value?: string[] | string) => {
    if (_.isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: value,
      }))
    } else if (_.isString(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: _.filter(prev.technologies, (technology) => technology !== value),
      }))
    }
  }

  const getTooltipTitle = (status: any) => {
    let result

    if (status === 'Impending') {
      result = 'The component is approaching its End-of-Life (EOL) and needs attention soon to remain supported.'
    }
    if (status === 'Outdated') {
      result = 'The component has surpassed its End-of-Life (EOL) and is no longer supported, requiring immediate action.'
    }
    if (status === 'Supported') {
      result = 'The component is within its supported lifecycle and requires no immediate action.'
    }

    return result
  }

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Grid container>
        <FormControl size="small" fullWidth sx={{ mb: 2 }}>
          <InputLabel>Policy</InputLabel>
          <Select value={selectedPolicy.id} onChange={(e) => handleSelectedPolicyChange(e.target.value)} label="Policy">
            {_.map(policies, (policy) => (
              <MenuItem key={policy.id} value={policy.id}>
                <Typography variant="caption">{policy.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container flexDirection="row" alignItems="center" onClick={toggleStatusExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{statusExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Status
          </Typography>
        </Grid>
        <Collapse in={statusExpanded}>
          {_.map(['Impending', 'Outdated', 'Supported'], (status) => (
            <Grid item xs={12} key={status}>
              <FormControlLabel
                key={status}
                control={
                  <Checkbox
                    checked={filters.status.includes(status)}
                    size="small"
                    onChange={(e) => {
                      const newStatus = e.target.checked ? [...filters.status, status] : filters.status.filter((s) => s !== status)

                      handleFilterChange('status', newStatus)
                    }}
                    sx={{ ml: 4 }}
                  />
                }
                label={
                  <Tooltip title={getTooltipTitle(status)}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                      {status}
                      {/*<Tooltip title={'gfasg'}>*/}
                      {/*  <Info key={status} sx={{ fontSize: '10px' }} />*/}
                      {/*</Tooltip>*/}
                    </Typography>
                  </Tooltip>
                }
              />
            </Grid>
          ))}
        </Collapse>

        <Grid container flexDirection="row" alignItems="center" onClick={toggleTechnologiesExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{technologiesExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Technology ({filters.technologies.length})
          </Typography>
        </Grid>
        <Collapse in={technologiesExpanded} sx={{ width: '100%' }}>
          <Grid container>
            <Autocomplete
              multiple
              freeSolo
              options={technologies}
              value={filters.technologies}
              onChange={handleTechnologiesChange}
              filterSelectedOptions
              noOptionsText="No options"
              sx={{ ml: 4, width: '100%' }}
              renderTags={() => null}
              ListboxProps={{
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  },
                },
              }}
              renderOption={(props, option) => {
                const { key, ...rest } = props
                return (
                  <Tooltip title={option} placement="right" key={key} arrow>
                    <li {...rest}>{option}</li>
                  </Tooltip>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search technologies"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      minHeight: '28px',
                      fontSize: '12px',
                    },
                  }}
                />
              )}
            />
            {filters.technologies.length > 0 && (
              <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                {_.map(filters.technologies, (technology) => (
                  <Tooltip title={technology} key={technology}>
                    <FilteredChip
                      key={technology}
                      label={technology}
                      size="small"
                      onDelete={() => {
                        handleTechnologiesChange(null, technology)
                      }}
                      sx={{
                        margin: 0.2,
                      }}
                    />
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        </Collapse>

        <Grid container flexDirection="row" alignItems="center" onClick={toggleTagsExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{tagsExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Tags ({filters.tags.length})
          </Typography>
        </Grid>
        <Collapse in={tagsExpanded} sx={{ width: '100%' }}>
          <Grid container>
            <Autocomplete
              multiple
              freeSolo
              options={tags}
              value={filters.tags}
              onChange={handleTagChange}
              filterSelectedOptions
              noOptionsText="No options"
              sx={{ ml: 4, width: '100%' }}
              renderTags={() => null}
              ListboxProps={{
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  },
                },
              }}
              renderOption={(props, option) => {
                const { key, ...rest } = props
                return (
                  <Tooltip title={option} placement="right" key={key} arrow>
                    <li {...rest}>{option}</li>
                  </Tooltip>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search tags"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      minHeight: '28px',
                      fontSize: '12px',
                    },
                  }}
                />
              )}
            />
            {tags.length > 0 && (
              <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                {_.map(filters.tags, (tag) => (
                  <Tooltip title={tag} key={tag}>
                    <FilteredChip
                      key={tag}
                      label={tag}
                      size="small"
                      onDelete={() => {
                        handleTagChange(null, tag)
                      }}
                      sx={{
                        margin: 0.2,
                      }}
                    />
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Paper>
  )
}

export default FiltersSidebar
