import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { getAxiosInstance } from "../../axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DrafttLogo } from "../../common.css";

const AwsMarketplaceSignup = (data: {awsMarketplaceToken: string}) => {
    const navigate = useNavigate()

    const [orgName, setOrgName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [formCompleted, setFormCompleted] = useState(true)
    const [error, setError] = useState<undefined | string>(undefined)
    const [succesfulSignup, setSuccessfulSignup] = useState(false)
    const [loading, setLoading] = useState(false)

    const validName = (name: string) => {
        return name.length >= 3
    }
    const validEmail = (email: string) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
    }
    useEffect(() => {
        setFormCompleted((validName(firstName) && validName(lastName) && validEmail(email) && validName(orgName)))
    }, [email, firstName, lastName, orgName])

    const handleSignup = async () => {
        setLoading(true)
        setError(undefined)
        try{
            await getAxiosInstance().post('/register/signup/aws-marketplace', {
                orgName,
                firstName: firstName,
                lastName: lastName,
                email,
                awsMarketplaceToken: data.awsMarketplaceToken,
            })
            setSuccessfulSignup(true)
        }catch(err: any){
            setError(err?.response?.data?.error || 'Internal error')
        }finally{
            setLoading(false)
        }
    }
    if (!succesfulSignup){
        return (
            <Grid container maxWidth="sm" mt={5}>
              <Grid container item xs={12} textAlign="center" mb={3}>
                <Grid item xs={12} textAlign="center">    
                  <DrafttLogo></DrafttLogo>
                </Grid>
                <Grid item xs={12} textAlign="center">    
                  <Typography variant="h5">
                    Thank you for purchasing draftt through the AWS marketplace
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">    
                  <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    Please enter the information below to proceed
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container
                border={"1px solid #e0e0e0"}
                p={3}
                borderRadius={"8px"}
                boxShadow={2}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Organization information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Organization name"
                    fullWidth
                    error={!!orgName.length && !validName(orgName)}
                    helperText={!!orgName.length && !validName(orgName) ? 'Organization name must be at least 3 characters' : ''}
                    variant="outlined"
                    onChange={(e) => setOrgName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Signup information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email Address"
                    fullWidth
                    error={!!email.length && !validEmail(email)}
                    helperText={!!email.length && !validEmail(email) ? 'Must be a valid email address' : ''}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="First name"
                    fullWidth
                    required
                    error={!!firstName.length && !validName(firstName)}
                    helperText={!!firstName.length && !validName(firstName) ? 'First name must be at least 3 characters' : ''}
                    onChange={(e) => setFirstName(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Last name"
                    fullWidth
                    error={!!lastName.length && !validName(lastName)}
                    helperText={!!lastName.length && !validName(lastName) ? 'Last name must be at least 3 characters' : ''}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!formCompleted || loading}
                    onClick={handleSignup}
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Create organization
                  </Button>
                  {loading && <CircularProgress />}
                </Grid>
                {error && <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight="bold" color={'red'}>
                    {error}
                  </Typography>
                </Grid>}
              </Grid>
            </Grid>
          )   
    }else{
        return <Grid container maxWidth="sm" sx={{ mt: 5 }}>
          <Grid item xs={12} textAlign="center">    
            <DrafttLogo></DrafttLogo>
          </Grid>
          <Grid item xs={12} textAlign="center" mb={3}>    
            <Typography variant="h5" fontWeight="bold">
                Registration completed succesfully, click below to sign in
            </Typography>
          <Grid item xs={12} textAlign="center">
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(e) => navigate('/')}
                  fullWidth
                  sx={{ mt: 2 }}
              >
                  Sign in
              </Button>
            </Grid>
          </Grid>
        </Grid>
        

    }
};

export default AwsMarketplaceSignup;