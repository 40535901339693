import { Dispatch, SetStateAction, useState } from 'react'
import { TimelineNode } from './futureTimelineV2'
import { Divider, Grid, Popover, Typography } from '@mui/material'
import { InventoryFilters } from '../..'
import dayjs from 'dayjs'

const TimelineNodeComponent = ({
  nodeData,
  setFilters,
  isSelected,
}: {
  nodeData: { node: TimelineNode; date: string; passedDueDate?: boolean }
  setFilters: Dispatch<SetStateAction<InventoryFilters>>
  isSelected: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const passedDueDate = nodeData.passedDueDate ?? false
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!isSelected) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const nodeRadius = Math.min(15, 10 + nodeData.node.totalComponentAmount / 10)
  const nodeColor = passedDueDate ? '#d32f2f' : '#4E3CAE'
  const open = Boolean(anchorEl)
  const handleOnClick = () => {
    setFilters((previous) => ({
      ...previous,
      dueDate: !isSelected ? nodeData.date : undefined,
    }))

    handlePopoverClose()
  }
  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={handleOnClick}
      style={{
        width: 8,
        height: 8,
        // width: isSelected ? Math.min(nodeRadius * 2, 20) : nodeRadius,
        // height: isSelected ? Math.min(nodeRadius * 2, 20) : nodeRadius,
        borderRadius: '50%',
        backgroundColor: nodeColor,
      }}
    >
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key={`${nodeData.date}`}
        onClose={handlePopoverClose}
        disableAutoFocus
      >
        <Grid container sx={{ p: 1, maxWidth: 350 }} flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="caption" color={nodeColor} sx={{ fontWeight: 500, mb: 0 }}>
            {dayjs(nodeData.date).format('MMM D YYYY').toString()}
          </Typography>
          {Object.entries(nodeData.node.technologies).map(([techName, details], index) => {
            return (
              <Grid item sx={{ width: '100%' }} key={`${nodeData.date}-${techName}-${index}`}>
                <Grid
                  item
                  container
                  key={`${nodeData.date}-${techName}-${index}`}
                  alignItems={'center'}
                  flexDirection={'column'}
                  sx={{ width: '100%' }}
                >
                  <Typography variant="caption" sx={{ fontSize: '12px', fontWeight: 500 }} align="center">
                    {techName}
                  </Typography>
                  <Typography
                    align="center"
                    variant="caption"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: 'text.secondary',
                    }}
                  >
                    Versions: {Array.from(details.versions).join(', ')}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }} align="center">
                    Components: {details.componentAmount}
                  </Typography>
                </Grid>
                {nodeData.node.totalTechnologyAmount > 1 && index !== nodeData.node.totalTechnologyAmount - 1 && (
                  <Grid item>
                    <Divider sx={{ width: '100%' }} key={`divider-${nodeData.date}-${index}`} />
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      </Popover>
    </div>
  )
}
export default TimelineNodeComponent
