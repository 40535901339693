import { styled } from '@mui/material/styles'
import { Chip } from '@mui/material'

export const FilteredChip = styled(Chip)({
  borderRadius: 7,
  background: '#393a70',
  color: 'white',
  height: '20px',
  '& .MuiChip-label': {
    px: 1,
    fontSize: '12px',
    lineHeight: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '140px',
  },
  '& .MuiChip-deleteIcon': {
    color: 'white',
    fontSize: '14px',
    '&:hover': { color: 'white', opacity: 0.8 },
  },
})
