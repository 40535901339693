import React, { useEffect, useState } from 'react'
import { getAxiosInstance } from '../../../../../axiosInstance'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../loader'
import _ from 'lodash'
import { DataGridPro, GridColDef, GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import dic from '../../../../../dictionary'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { ArrowBack, AutoAwesomeOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { AWS_TECHNOLOGIES, ROUTES } from '../../../../../consts'
import { TechnologyIcon } from '../../default/defaultTechnologyCatalog.style'
import dayjs from 'dayjs'
import { TechnologyLogo } from '../components/repositories/repositories.style'
import { VersionList } from './repoView.style'

const EcrRepoView = () => {
  const navigate = useNavigate()
  const { repoName } = useParams<{ repoName: string }>()
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [repoData, setRepoData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ecrComponents = await getAxiosInstance().get('/component', { params: { technology: AWS_TECHNOLOGIES.ECR } })
        const parsedRepoData = parseRepoData(ecrComponents.data)
        setRepoData(parsedRepoData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const parseRepoData = (ecrComponents: any) => {
    const parsedEcrComponents = _.map(ecrComponents, (ecrComponent) => {
      const architectures = _.get(ecrComponent, 'details.architectures')

      return {
        ...ecrComponent,
        repoName: _.get(ecrComponent, 'details.repositoryDetails.repository.repositoryName'),
        tag: _.get(ecrComponent, 'details.tagInfo.tag'),
        osArch: _.map(architectures, (arch) => _.compact([arch.os, arch.architecture, arch.variant]).join('/')),
        technology: _.get(ecrComponent, 'displayName') || _.get(ecrComponent, 'type'),
        technologiesVersions: _.get(ecrComponent, 'version'),
        updatedAt: dayjs(ecrComponent.updatedAt).format('MMM D, YYYY'),
      }
    })

    const repoComponents = _.filter(parsedEcrComponents, (ecrComponent) => ecrComponent.repoName === repoName)

    const repoDataGroupByTag = _.groupBy(repoComponents, 'tag')

    const repoData = _.map(repoDataGroupByTag, (items, tag) => {
      const imageDetails: any = _.head(items)
      const technologyVersions = _.fromPairs(
        _.uniq(_.flatMap(repoComponents, (item) => _.get(item, 'displayName'))).map((tech) => [
          tech,
          _.get(_.find(items, { technology: tech }), 'version', ''),
        ]),
      )

      return {
        tag,
        osArch: imageDetails.osArch,
        region: imageDetails.region,
        isLatest:
          _.get(imageDetails, 'details.tagInfo.latestImage') === true
            ? dic.catalog.specific_technology.ecr.repo_view.table.is_latest.yes
            : dic.catalog.specific_technology.ecr.repo_view.table.is_latest.no,
        isMultiArchitecture:
          _.get(imageDetails, 'details.isMultiArchitecture') === true
            ? dic.catalog.specific_technology.ecr.repo_view.table.is_multi_arch.yes
            : dic.catalog.specific_technology.ecr.repo_view.table.is_multi_arch.no,
        repositoryDetails: _.get(imageDetails, 'details.repositoryDetails.repository'),
        technologies: _.chain(items).map('type').uniq().map(_.capitalize).sortBy().value(),
        technologiesVersions: _.sortBy(
          _.map(items, (item) => ({
            technology: item.technology,
            version: item.version,
            explanation: _.get(item, 'details.technologyDetails.guessedExplanation'),
          })),
          'technology',
        ),
        manifestDigest: _.get(imageDetails, 'details.imageManifest.digest'),
        pushedAt: dayjs(_.get(_.head(items), 'details.tagInfo.pushedAt')).format('MMM D, YYYY'),
        latestPull: dayjs(_.get(_.head(items), 'details.tagInfo.latestPulledAt')).format('MMM D, YYYY'),
        updatedAt: imageDetails.updatedAt,
        ...technologyVersions,
      }
    })

    return repoData
  }

  if (loading) {
    return <Loader />
  }

  const onBackIconClick = () => {
    navigate(_.replace(ROUTES.CATALOG.SPECIFIC_TECHNOLOGY, ':technologyName', AWS_TECHNOLOGIES.ECR))
  }

  const getOsArchitecturesTooltipTitle = (osArchitectures: any) => {
    return (
      <>
        {_.map(osArchitectures, (osArch, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'start', mb: '4px' }}>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <span>{osArch}</span>
            </Typography>
          </Grid>
        ))}
      </>
    )
  }

  const generateTechnologyColumns = () => {
    const uniqueTechnologies = _.uniq(_.flatMap(repoData, (item) => _.map(item.technologiesVersions, 'technology')))

    return _.map(uniqueTechnologies, (tech) => ({
      field: tech,
      headerName: tech,
      hide: true,
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const techData = _.find(params.row.technologiesVersions, {
          technology: tech,
        })
        const version = _.get(techData, 'version', '')
        const explanation = _.get(techData, 'explanation')

        return (
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              {explanation && (
                <Tooltip title={explanation}>
                  <AutoAwesomeOutlined fontSize="small" sx={{ mr: '4px', width: '15px', verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {version}
            </Typography>
          </Grid>
        )
      },
    }))
  }

  const columns: GridColDef[] = [
    {
      field: 'tag',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.tag,
      flex: 1,
      sortable: true,
    },
    {
      field: 'technologies',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.technologies,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Grid flexDirection="row" alignItems="center">
          {_.map(params.value, (tech) => (
            <Tooltip key={tech} title={tech}>
              <TechnologyLogo key={tech} src={`https://draftt-public.s3.amazonaws.com/icons/${_.toLower(tech)}.svg`} alt={tech} />
            </Tooltip>
          ))}
        </Grid>
      ),
    },
    {
      field: 'isLatest',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.is_latest.title,
      flex: 1,
      sortable: true,
    },
    {
      field: 'isMultiArchitecture',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.is_multi_arch.title,
      flex: 1,
      sortable: true,
    },
    {
      field: 'osArch',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.osArch,
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const osArchitectures = _.get(params, 'row.osArch', [])
        const displayedOsArchitectures = _.take(osArchitectures, 2)
        const hasMore = osArchitectures.length > 2

        return (
          <Grid container flexDirection="column" justifyContent="center" alignItems="start" sx={{ height: '100%' }}>
            <Tooltip title={getOsArchitecturesTooltipTitle(osArchitectures)}>
              <Grid container flexDirection="column">
                {_.map(displayedOsArchitectures, (osArch: any, index: number) => (
                  <Grid item key={osArch} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                      {osArch} {hasMore && index === displayedOsArchitectures.length - 1 ? '...' : ''}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Tooltip>
          </Grid>
        )
      },
      sortComparator: (v1: string, v2: string) => {
        const title1 = _.toLower(v1 || '')
        const title2 = _.toLower(v2 || '')
        return title1.localeCompare(title2)
      },
    },
    {
      field: 'manifestDigest',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.manifest_digest,
      flex: 2,
      sortable: true,
    },
    {
      field: 'pushedAt',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.pushed_at,
      flex: 1,
      sortable: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'latestPull',
      headerName: dic.catalog.specific_technology.ecr.repo_view.table.last_pull,
      flex: 1,
      sortable: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    ...generateTechnologyColumns(),
  ]

  const getDetailPanelContent = (params: any) => {
    return (
      <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <strong>{dic.catalog.specific_technology.ecr.repo_view.table.updated_at} </strong>
          {params.row.updatedAt}
        </Grid>
        <Grid item>
          <strong> {dic.catalog.specific_technology.ecr.repo_view.table.versions}: </strong>
          <VersionList>
            {_.map(params.row.technologiesVersions, (technologyVersionItem: any, index: number) => {
              return (
                <li key={index}>
                  <Grid container alignItems="center">
                    <Grid item>
                      {technologyVersionItem.technology} - {technologyVersionItem.version}
                    </Grid>
                    {technologyVersionItem.explanation && (
                      <Grid item container alignItems="center" sx={{ m: '8px' }}>
                        <AutoAwesomeOutlined
                          fontSize="small"
                          sx={{
                            marginRight: '4px',
                          }}
                        />
                        <span>{technologyVersionItem.explanation}</span>
                      </Grid>
                    )}
                  </Grid>
                </li>
              )
            })}
          </VersionList>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <TechnologyIcon src={`https://draftt-public.s3.amazonaws.com/icons/${AWS_TECHNOLOGIES.ECR}.svg`} alt={AWS_TECHNOLOGIES.ECR} />
        <h1>
          {dic.catalog.specific_technology.title} / {dic.catalog.specific_technology.ecr.title} / {repoName} ({repoData.length})
        </h1>
      </Box>
      <Grid container>
        <DataGridPro
          rows={repoData}
          columns={columns}
          disableRowSelectionOnClick={true}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10]}
          getRowId={(row) => row.tag}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          slots={{
            detailPanelExpandIcon: KeyboardArrowDown,
            detailPanelCollapseIcon: KeyboardArrowUp,
            toolbar: () => {
              return (
                <GridToolbarContainer sx={{ pt: '8px' }}>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                </GridToolbarContainer>
              )
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'tag', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {
                ..._.fromPairs(_.map(generateTechnologyColumns(), (col) => [col.field, false])),
              },
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default EcrRepoView
